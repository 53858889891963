.homeBackground {
  background: url("../images/home_bg.png");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
  background-size: 100% 100%;
  background-attachment: fixed;
}
@media (min-width: 1024px) {
  .homeBackground {
    background: url("../images/pc_bg.png");
  }
}
.homeStartBtnWrapper {
  position: fixed;
  z-index: 1;
  bottom: 233px;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.homeStartBtnImage {
  width: 343px;
  height: 142px;
}
.modalWrapper {
  /* width: 690px;
  height: 428px; */
}

.ant-modal-content {
  border-radius: 12px !important;
  background: linear-gradient(90deg, #275752 0%, #1d4255 52%, #2b6150 100%) rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 12px 0px rgba(76, 232, 205, 0.5), inset 0px -2px 2px 0px rgba(32, 229, 175, 0.5);
  border-radius: 9px 9px 9px 9px;
  opacity: 1;
  padding: 30px 20px;
}

.btnStyle {
  width: 100%;
  background-color: none;
  border-color: #00ffff;

  width: 32px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
}
