.anticon,
.anticon-global > svg {
  color: #fff;
  font-size: 20px;
}

.anticon-share-alt > svg {
  /* color: #fff; */
  font-size: 20px;
}
