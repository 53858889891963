.mainWrapper {
  background: url("../../images/main_bg.png");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
  background-size: 100% 100%;
  background-attachment: fixed;
}
@media (min-width: 1024px) {
  .mainWrapper {
    background: url("../../images/pc_bg.png");
  }
}
.mainHeaderWrapper {
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 66px;
  background: #161515;
  padding: 0 15px;
}

.walletWrapper {
  height: 46px;
  padding: 4.5px 9px;
  /* width: 84px; */
  /* height: 24px; */
  background: #04ffd2;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;

  font-size: 20px;
  font-weight: 500;
  color: #212225;
  line-height: 27px;
}
.logoIcon {
  width: 204px;
  height: 48px;
}
.walletIcon {
  width: 19px;
  height: 19px;
}

.paddingWrapper {
  padding: 81px 15px 20px 15px;
}

.accountRow {
  /* height: 99px; */
  background: rgba(21, 43, 32, 0.8);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #04ffd2;
  padding: 15px;

  display: flex;
  flex-direction: column;
}

.copyIcon {
  width: 19px;
  height: 19px;
}
.accountAddress {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.accountBalance {
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
}

.titleWrapper {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 15px;
}

.titleText {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: left;
  border-bottom: 2px solid #04ffd2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding-bottom: 4px;
}

.cardItem {
  min-height: 80px;
  background: rgba(21, 42, 32, 0.8);
  box-shadow: 0px 3px 4px 0px rgba(96, 87, 6, 0.3);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #04ffd2;

  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardNumberText {
  font-size: 30px;
  font-weight: 900;
  color: #ffffff;
  line-height: 24px;
}
.cardTipText {
  margin-top: 15px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}

.approveWrapper {
  margin-top: 20px;
  padding-bottom: 60px;
  /* height: 34px;
  background: rgba(21, 42, 32, 0.8);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #04ffd2; */
}
.arrowIconWrapper {
  top: -5px;
  position: relative;
  left: -5px;
  height: 100%;
}
.arrowIcon {
  width: 20px;
  height: 20px;
}

.ant-select-selector {
  background: rgba(21, 42, 32, 0.8) !important;
  border: 1px solid #04ffd2 !important;
  /* height: 50px; */
}
.ant-select-dropdown {
  background: rgba(21, 42, 32, 0.8) !important;
}
.ant-select-item {
  text-align: center;
}
.ant-select-item-option-content {
  color: #fff;
  font-size: 28px;
}

.ant-select-item-option {
  border: 0.5px solid #04ffd2 !important;
}
.ant-select-item-option-active .ant-select-item-option-content {
  color: #04ffd2 !important;
}

.ant-select-selection-item {
  color: #fff !important;
  font-size: 28px;
}

.inputTipRedText {
  font-size: 20px;
  font-weight: 500;
  color: #fbff00;
  line-height: 13px;
}

.investText {
  color: #fbff00;
}

.swapUsdtWrapper {
  height: 46px;
  background: rgba(21, 42, 32, 0.8);
  box-shadow: 0px 3px 4px 0px rgba(154, 67, 175, 0.3);
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #04ffd2;
  padding: 0 14px;
}
.swapUsdtAmountText {
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 46px;
}

.swapUsdText {
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 46px;
}

.ant-btn > span {
  font-size: 28px;
}

.paddingWrapper,
.ant-select-selector {
  height: 46px !important;
}
.ant-select-selection-item {
  line-height: 46px !important;
}
